import { Vue, Component } from "vue-property-decorator";
import { Route, Next } from "vue-router";
@Component({
    beforeRouteEnter(
        to: Route,
        from: Route,
        next: Next<ScrollToTopMixin>
    ) {
        next((vm: ScrollToTopMixin) => {
            vm.scrollToTop();
        })
    },
})
export default class ScrollToTopMixin extends Vue {
    scrollToTop() {
        const currentScroll =
            window.pageYOffset || document.body.scrollTop;
        if (currentScroll > 0) {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
    }
}