
import { Component, Mixins, Prop } from "vue-property-decorator";
import GoogleMapMixin from "../mixins/google-map.mixin";
import SchoolMixin from "@/mixins/school.mixin";
import CommonMixin from "@/mixins/common.mixin";
import { remove } from "lodash";

@Component({})
export default class LeftInfoPanel extends Mixins(
  GoogleMapMixin,
  SchoolMixin,
  CommonMixin
) {
  get staticMap() {
    return `https://maps.googleapis.com/maps/api/staticmap?center=${this.schoolAbout.addressLatitude},${this.schoolAbout.addressLongitude}&zoom=13&size=374x233&maptype=roadmap&markers=${this.schoolAbout.addressLatitude},${this.schoolAbout.addressLongitude}&key=AIzaSyDlxV24w9chETQD7DPhFQ4ibg1cSVPgUYs`;
  }

  mounted() {
    this.setCurrentlySearchedLocationMarker({
      searchText: this.SchoolAddress,
      latitude: this.getCoOrdinates[0],
      longitude: this.getCoOrdinates[1],
      distance: 5,
      gradelevel: "",
    });
  }
  @Prop({ default: null })
  infoPanelProps!: Map<string, string>;

  get ReportCardGrade(): string {
    return (
      (this.infoPanelProps && this.infoPanelProps.get("card-grade")) || "N/A"
    );
  }
  get yearFromEntity(){
    let result;
    if(this.schoolSystem)
    {
        result = this.overallPerformance.schoolSystemCurrentYear.letterGradeYearFormatted;
    }
      else{
        result = this.overallPerformance.schoolCurrentYear.letterGradeYearFormatted;
      }
    return result;
  }
  
  get AssementsRating(): string {
    return (
      (this.infoPanelProps && this.infoPanelProps.get("assesment-rating")) ||
      "N/A"
    );
  }
  get ProgressRating(): string {
    return (
      (this.infoPanelProps && this.infoPanelProps.get("progress-rating")) ||
      "N/A"
    );
  }

  get ActGrade(): string {
    return (
      (this.infoPanelProps && this.infoPanelProps.get("act-grade")) || "N/A"
    );
  }

  get GraduationGrade(): string {
    return (
      (this.infoPanelProps && this.infoPanelProps.get("graduation-grade")) ||
      "N/A"
    );
  }

  get StrengthOfDiplomaGrade(): string {
    return (
      (this.infoPanelProps &&
        this.infoPanelProps.get("strength-of-diploma-grade")) ||
      "N/A"
    );
  }

  get SchoolAddress(): string {
    return (
      (this.infoPanelProps && this.infoPanelProps.get("school-address")) || ""
    );
  }
  get SchoolPhone(): string {
    return (
      (this.infoPanelProps && this.infoPanelProps.get("school-phone")) || ""
    );
  }

  get SchoolEmail(): string {
    return (
      (this.infoPanelProps && this.infoPanelProps.get("school-email")) || ""
    );
  }
  get SchoolWeb(): string {
    return (this.infoPanelProps && this.infoPanelProps.get("school-web")) || "";
  }
  get SchoolTwitter(): string {
    return (
      (this.infoPanelProps && this.infoPanelProps.get("school-twitter")) || ""
    );
  }
  get SchoolFacebook(): string {
    return (
      (this.infoPanelProps && this.infoPanelProps.get("school-facebook")) || ""
    );
  }
  private getRatingColorClass(rating: string) {
    return `bg-rating-${rating.toLowerCase()}`;
  }
  get getCoOrdinates(): number[] {
    const lat =
      (this.infoPanelProps && this.infoPanelProps.get("school-latitude")) ||
      30.45994;
    const long =
      (this.infoPanelProps && this.infoPanelProps.get("school-longitude")) ||
      -91.18855;
    return [+lat, +long];
  }
  get detailType(): string {
    return (
      (this.infoPanelProps && this.infoPanelProps.get("detail-type")) || ""
    );
  }
  get performanceRating(): string {
    return (
      (this.infoPanelProps && this.infoPanelProps.get("performance-rating")) ||
      ""
    );
  }
  get starRating(): number {
    return +(
      (this.infoPanelProps && this.infoPanelProps.get("star-rating")) ||
      "0"
    );
  }
  get workSchedule(): string[][] {
    const data =
      (this.infoPanelProps && this.infoPanelProps.get("work-schedule")) || "";
    const daysArray = data.split(",").map((e) => {
      const [day, ...time] = e.split(":");
      return [day, time.join(":")];
    });
    return daysArray.filter((day) => Boolean(day[1]));
  }
  get licenseNumber(): string {
    return (
      (this.infoPanelProps && this.infoPanelProps.get("license-number")) || ""
    );
  }
  get siteCode(): string {
    return (this.infoPanelProps && this.infoPanelProps.get("site-code")) || "";
  }
  get googleMapURL() {
    return `http://maps.google.com/maps?q=${this.SchoolAddress.replaceAll(
      " ",
      "+"
    )}&loc:${this.getCoOrdinates[0]}+${this.getCoOrdinates[1]}&z=15`;
  }
}
