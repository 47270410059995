
import { Component, Mixins } from "vue-property-decorator";
import AsyncComputed from "vue-async-computed-decorator";
import { Route } from "vue-router";
import {
  ProfileType,
  SchoolTab,
  profileTypeArray,
} from "@/models/schoolfinder.enums";

import SchoolMixin from "@/mixins/school.mixin";
import LeftInfoPanel from "@/components/LeftInfoPanel.vue";
import { CompareFav } from "@/mixins/compare-fav.mixin";
import { School } from "@/models/interface";
import AppNotification from "@/components/AppNotification.vue";
import ScrollToTopMixin from "@/mixins/scroll-to-top.mixin";
import { setMetaInfo } from "@/assets/js/meta-info-config";
import { mapState } from "vuex";

@Component({
  components: {
    "left-info-panel": LeftInfoPanel,
    AppNotification
  },
  computed: { ...mapState("app", ["mobileView", "tabletView"]) },
})
export default class SchoolDetail extends Mixins(
  SchoolMixin,
  CompareFav,
  ScrollToTopMixin
) {

  @AsyncComputed()
  async getSchool() {
    await this.$store.dispatch(
      "app/getSchoolDetail",
      this.$route.params.schoolId
    );
   setMetaInfo({ title: this.schoolAbout.name!=""?this.schoolAbout.name:this.schoolAbout.districtName });
  }
  ProfileType = ProfileType;
  profileTypeArray = profileTypeArray;

  level = "Data Limitations for 2021. ";
  eclevel = "Early Childhood Data Limitations for 2020-2021. ";
  schoolTab = SchoolTab;

  showAbout = true;
  showAcademicPerformance = false;
  showDisciplineAndAttendance = false;
  showSpending = false;
  showCovid = false;
  showInspections = false;
  showDistrictSpending = false;
  showDistrictCovid = false;

  showAboutHighlighted = false;
  showAcademicPerformanceHighlighted = false;
  showDisciplineAndAttendanceHighlighted = false;
  showSpendingHighlighted = false;
  showCovidHighlited = false;
  showDistrictCovidHighlited = false;
  showInspectionsHighlited = false;
  showDistrictSpendingHighlighted = false;
  LeftInfoPanelProps: Map<string, string> = new Map();

  schoolIsToCompare = false;
  schoolIsFavorite = false;
  IsCareFacility=false;
  isEarlyChildhoodClicked = false;
  isK12Clicked = false;

  componentKey = 0;
  reload = false;

  stickyHeight = parseInt(
    document.querySelector(".sticky-topbar")?.clientHeight + ""
  );

  forceRerender() {
    this.componentKey += 1;
  }

  beforeRouteLeave(to: Route, from: Route, next: any) {
    sessionStorage.removeItem("selected-school");
  }

  mounted() {
  this.showInspection();
    this.$nextTick(() => {
    if (localStorage.getItem("englishIsSelected") == "1") {
      if (localStorage.getItem("reloaded")) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem("reloaded");
      } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem("reloaded", "1");
        location.reload();
      }
    }
      this.schoolIsFavorite = this.isSchoolAlreadyInFavList;
      this.schoolIsToCompare = this.isSchoolAlreadyInCompareList;
    });

    window.addEventListener('resize', this.setWindowSize);
    window.addEventListener('mousemove', this.setWindowSize);
  }

  get schoolAbout() {
    return this.school && this.school.schoolAbout;
  }

  get schoolspending() {
    return this.school && this.school.schoolSpending;
  }

  get earlyChildhoodDetailStyle() {
    return this.toggleButtonStyle(this.showEE);
  }

  get k12DetailStyle() {
    return this.toggleButtonStyle(this.showK12);
  }

  get bothOrK12() {
    return (
      this.schoolAbout &&
      (this.schoolAbout.profileType ===
        profileTypeArray[ProfileType.Both].name ||
        this.schoolAbout.profileType === profileTypeArray[ProfileType.K12].name)
    );
  }

  toggleButtonStyle(selected: boolean) {
    return {
      "bg-doe-dark-blue-darkest": !selected,
      "bg-unselected doe-darkest-blue": selected,
    };
  }

  onToggleClick() {
    this.onTabClick(SchoolTab.About);
  }

  onTabClick(selectedTab: SchoolTab) {
    this.showAbout = selectedTab === SchoolTab.About;
    this.showAcademicPerformance = selectedTab === SchoolTab.AcademicPerformance;
    this.showDisciplineAndAttendance = selectedTab === SchoolTab.DisciplineAndAttendance;
    this.showSpending = selectedTab === SchoolTab.Spending;
    this.showCovid = selectedTab === SchoolTab.COVID19Impact;
    this.showDistrictSpending = selectedTab === SchoolTab.DistrictSpending;
    this.showDistrictCovid = selectedTab === SchoolTab.DistrictCOVID19Impact;
    this.showInspections = selectedTab === SchoolTab.Inspections;


    switch (selectedTab) {
      case SchoolTab.About:
        if (this.showK12)
          this.$router.push(
            `/schools/${this.$route.params.schoolId}/about-our-school`
          );
        else if (this.showEE)
          this.$router.push(
            `/schools/${this.$route.params.schoolId}/ec-school-about`
          );
        break;

      case SchoolTab.AcademicPerformance:
        if (this.showK12)
          this.$router.push(
            `/schools/${this.$route.params.schoolId}/academic-performance/academic-performance`
          );
        else if (this.showEE)
          this.$router.push(
            `/schools/${this.$route.params.schoolId}/ec-academic-performance`
          );
        break;

      case SchoolTab.DisciplineAndAttendance:
        this.$router.push(`/schools/${this.$route.params.schoolId}/DisciplineandAttendance`);
        break;
        
      case SchoolTab.Spending:
        this.$router.push(`/schools/${this.$route.params.schoolId}/finance`);
        break;

      case SchoolTab.COVID19Impact:
        this.$router.push(
          `/schools/${this.$route.params.schoolId}/COVID19Impact`
        );
        break;

      case SchoolTab.DistrictCOVID19Impact:
        this.$router.push(
          `/schools/${this.$route.params.schoolId}/DistrictCOVID19Impact`
        );
        break;

      case SchoolTab.DistrictSpending:
        this.$router.push(
          `/schools/${this.$route.params.schoolId}/DistrictSpending`
        );
        break;

      case SchoolTab.Inspections:
        this.$router.push(`/schools/${this.$route.params.schoolId}/Inspections`);
        break;

      default:
        break;
    }
  }

  onMouseover(item: any) {
    this.showAboutHighlighted = item.selectedTabItem === SchoolTab.About;
    this.showAcademicPerformanceHighlighted = !(this.showAcademicPerformanceTabSelected || this.showAcademicPerformance) && item.selectedTabItem === SchoolTab.AcademicPerformance;
    this.showDisciplineAndAttendanceHighlighted = !(this.showDisciplineAndAttendanceTabSelected || this.showDisciplineAndAttendance) && item.selectedTabItem === SchoolTab.DisciplineAndAttendance;
    this.showSpendingHighlighted = !(this.showFinanceTabSelected || this.showSpending) && item.selectedTabItem === SchoolTab.Spending;
    this.showCovidHighlited = !(this.showCovidTabSelected || this.showCovid) && item.selectedTabItem === SchoolTab.COVID19Impact;
    this.showDistrictSpending = !(this.showDistrictSpendingTabSelected || this.showDistrictSpending) && item.selectedTabItem === SchoolTab.DistrictSpending;
    this.showInspectionsHighlited = !(this.showInspectionsTabSelected || this.showInspections) && item.selectedTabItem === SchoolTab.Inspections;    
    this.showDistrictCovidHighlited = !(this.showDistrictCovidTabSelected || this.showDistrictCovid) && item.selectedTabItem === SchoolTab.DistrictCOVID19Impact;
  }

  onMouseleave(item: any) {
    switch (item.selectedTabItem) {
      case SchoolTab.About:
        this.showAboutHighlighted = false;
        break;
      case SchoolTab.AcademicPerformance:
        this.showAcademicPerformanceHighlighted = false;
        break;
      case SchoolTab.DisciplineAndAttendance:
        this.showDisciplineAndAttendanceHighlighted = false;
        break;
      case SchoolTab.Spending:
        this.showSpendingHighlighted = false;
        break;
      case SchoolTab.COVID19Impact:
        this.showCovidHighlited = false;
        break;
      case SchoolTab.DistrictCOVID19Impact:
        this.showDistrictCovidHighlited = false;
        break;
      case SchoolTab.DistrictSpending:
        this.showDistrictSpendingHighlighted = false;
        break;
      case SchoolTab.Inspections:
        this.showInspectionsHighlited = false;
        break;
      default:
        break;
    }
  }

  get aboutTab() {
    const active =
      this.showAbout &&
      ((this.showK12 &&
        !this.showAcademicPerformanceTabSelected &&
        !this.showDisciplineAndAttendanceTabSelected &&
        !this.showFinanceTabSelected &&
        !this.showCovidTabSelected && !this.showInspectionsTabSelected) ||
        (this.showEE && !this.showAcademicPerformanceTabSelected
        && !this.showInspectionsTabSelected));

    const aboutNotSelected =
      !this.showAbout ||
      this.showAcademicPerformanceTabSelected ||
      this.showDisciplineAndAttendanceTabSelected ||
      this.showFinanceTabSelected ||
      this.showCovidTabSelected ||
      this.showInspectionsTabSelected;
       return {
      active,
      "doe-dark-blue": active,
      "inactive-tab": !active,
      "doe-dark-blue-darker": aboutNotSelected,
      "bg-doe-light-violet": aboutNotSelected && this.showAboutHighlighted,
    };
  }

  get academicPerformanceTab() {
    const active =
      this.showAcademicPerformance || this.showAcademicPerformanceTabSelected;
    return {
      active,
      "doe-dark-blue": active,
      "inactive-tab": !active,
      "doe-dark-blue-darker": !active,
      "bg-doe-light-violet": !active && this.showAcademicPerformanceHighlighted,
    };
  }

  get disciplineAndAttendanceTab() {
    const active =
      this.showDisciplineAndAttendance || this.showDisciplineAndAttendanceTabSelected;
    return {
      active,
      "doe-dark-blue": active,
      "inactive-tab": !active,
      "doe-dark-blue-darker": !active,
      "bg-doe-light-violet": !active && this.showDisciplineAndAttendanceHighlighted,
    };
  }

  get spendingTab() {
    const active = this.showSpending || this.showFinanceTabSelected;
    return {
      active,
      "doe-dark-blue": active,
      "inactive-tab": !active,
      "doe-dark-blue-darker": !active,
      "bg-doe-light-violet": !active && this.showSpendingHighlighted,
    };
  }

  get covidTab() {
    const active = this.showCovid || this.showCovidTabSelected;
    return {
      active,
      "doe-dark-blue": active,
      "inactive-tab": !active,
      "doe-dark-blue-darker": !active,
      "bg-doe-light-violet": !active && this.showCovidHighlited,
    };
  }

  get districtCovidTab() {
    const active = this.showDistrictCovid || this.showDistrictCovidTabSelected;
    return {
      active,
      "doe-dark-blue": active,
      "inactive-tab": !active,
      "doe-dark-blue-darker": !active,
      "bg-doe-light-violet": !active && this.showDistrictCovidHighlited,
    };
  }

  get districtSpendingTab() {
    const active = this.showDistrictSpendingHighlighted || this.showDistrictSpendingTabSelected;
    return {
      active,
      "doe-dark-blue": active,
      "inactive-tab": !active,
      "doe-dark-blue-darker": !active,
      "bg-doe-light-violet": !active && this.showDistrictSpendingHighlighted,
    }
  }

    get InspectionsTab() {
    const active = this.showInspections || this.showInspectionsTabSelected;
    return {
      active,
      "doe-dark-blue": active,
      "inactive-tab": !active,
      "doe-dark-blue-darker": !active,
      "bg-doe-light-violet": !active && this.showInspectionsHighlited,
    };
  }

  get disciplineAndAttendance() {
    return this.disciplineAndAttendance && this.school.disciplineAndAttendance;
  }

  get schoolSpending() {
    return this.school && this.school.schoolSpending;
  }

  get detailsFiltered() {
    let result;
    if (!this.schoolSystem) {
      result = this.details.filter(
        (tab) => tab.selectedTabItem !== this.schoolTab.DistrictSpending
      );
    }
    else {
      result = this.details.filter(
        (tab) => tab.selectedTabItem === this.schoolTab.AcademicPerformance ||
                 tab.selectedTabItem === this.schoolTab.DistrictSpending ||
                 tab.selectedTabItem === this.schoolTab.DisciplineAndAttendance
      );
    }
    return result;
  }

  get detailsFilteredBySchoolType() {
    let result;
    if (this.showK12) {
      if (this.schoolAbout.schooltype == "Scholarship") {
        result = this.detailsFiltered.filter(
          (tab) => tab.selectedTabItem !== this.schoolTab.Spending
        );
      } else {
        result = this.detailsFiltered;
      }
    } else if (this.showEE){
      result = this.detailsFiltered.filter(
        (tab) =>
          tab.selectedTabItem !== this.schoolTab.Spending &&
          tab.selectedTabItem !== this.schoolTab.COVID19Impact
      );
     if (!this.IsCareFacility)
      result = result.filter(
        (tab) =>
          tab.selectedTabItem !== this.schoolTab.Inspections 
      );
    }
      
    return result;
  }

  get details() {
    if(this.showK12)
    {
    return [
      {
        id: 1,
        selectedTabClass: this.aboutTab,
        selectedTabItem: this.schoolTab.About,
        labelName: "About",
      },
      {
        id: 2,
        selectedTabClass: this.academicPerformanceTab,
        selectedTabItem: this.schoolTab.AcademicPerformance,
        labelName: "Academic Performance",
      },
      {
        id: 3,
        selectedTabClass: this.spendingTab,
        selectedTabItem: this.schoolTab.Spending,
        labelName: "Spending",
      },
      {
        id: 4,
        selectedTabClass: this.districtSpendingTab,
        selectedTabItem: this.schoolTab.DistrictSpending,
        labelName: "Spending",
      },
      {
        id: 5,
        selectedTabClass: this.disciplineAndAttendanceTab,
        selectedTabItem: this.schoolTab.DisciplineAndAttendance,
        labelName: "Attendance/Discipline",
      },
    ];
    }
    else
    {
      return [
      {
        id: 1,
        selectedTabClass: this.aboutTab,
        selectedTabItem: this.schoolTab.About,
        labelName: "About",
      },
      {
        id: 2,
        selectedTabClass: this.academicPerformanceTab,
        selectedTabItem: this.schoolTab.AcademicPerformance,
        labelName: "Academic Performance",
      },
      {
        id: 3,
        selectedTabClass: this.spendingTab,
        selectedTabItem: this.schoolTab.Spending,
        labelName: "Spending",
      },
      {
        id: 4,
        selectedTabClass: this.districtSpendingTab,
        selectedTabItem: this.schoolTab.DistrictSpending,
        labelName: "Spending",
      },
      {
        id: 5,
        selectedTabClass: this.InspectionsTab,
        selectedTabItem: this.schoolTab.Inspections,
        labelName: "Inspections"
      }
    ];
    }
  }

  get schoolIndex(): number {
    const tmp = sessionStorage.getItem("schoolIndex");
    if (tmp == null) {
      return 0;
    }
    return +tmp;
  }

  get isSchoolAlreadyInFavList(): boolean {
    const existingFavList = this.getObjectFromSessionStorage("favList");
    if (this.isNotEmpty(existingFavList)) {
      for (const i in existingFavList) {
        if (existingFavList[i].entityId == this.$route.params.schoolId) {
          return true;
        }
      }
    }
    return false;
  }

  get isSchoolAlreadyInCompareList(): boolean {
    const existingCompareList = this.getObjectFromSessionStorage("compareList");
    if (this.isNotEmpty(existingCompareList)) {
      for (const i in existingCompareList) {
        if (existingCompareList[i].entityId == this.$route.params.schoolId) {
          return true;
        }
      }
    }
    return false;
  }

  get currentSchool(): School {
    const school: School = this.getObjectFromSessionStorage("selected-school");
    if (school && school.entityId) {
      if (this.$route.params.schoolId != school.entityId) {
        return this.createSchoolForCompFav();
      } else return school;
    } else {
      return this.createSchoolForCompFav();
    }
  }

  createSchoolForCompFav() {
    const leftPanel = this.school && this.school.leftPanel;
    const school = new School();

    if (this.schoolAbout) {
      school.name = this.schoolAbout.name;
      school.uniqueId = this.schoolAbout.uniqueId;
      school.gradesServed = this.schoolAbout.gradeservedcurrentsy;
      school.entityId = this.schoolAbout.uniqueId;
      school.profileType = this.schoolAbout.profileType;
    }
    
    if (this.overallPerformance) {
      school.gradeLetter =
        this.overallPerformance.schoolCurrentYear.letterGrade;

      if (this.overallPerformance.schoolCurrentYear.letterGrade == "")
        school.gradeLetterFormatted = "N/A";
      else
        school.gradeLetterFormatted =
          this.overallPerformance.schoolCurrentYear.letterGrade;
    }

    school.sps = leftPanel.studentPerformanceScore;
    school.siteRating = leftPanel.siteRating;
    school.siteScore = leftPanel.siteScore;

    return school;
  }

  goToCovid19ImpactPage(): void {
    this.onTabClick(this.schoolSystem ? SchoolTab.DistrictCOVID19Impact : SchoolTab.COVID19Impact);
  }

  addSchoolToCompareList() {
    if (this.latestCompareCount() == this.maxNumberOfComparePossible) {
      this.$bvToast.show("maxCompareLimit");
    } else {
      this.schoolIsToCompare = true;
      this.addToCompareList(this.currentSchool, this.latestCompareCount());
    }
  }

  addSchoolToFavList() {
    this.schoolIsFavorite = true;
    this.addToFavList(this.currentSchool, this.latestCompareCount());
  }

  removeSchoolFromCompareList() {
    this.schoolIsToCompare = false;
    this.removeFromCompareList(this.currentSchool);
  }

  removeSchoolFromFavList() {
    this.schoolIsFavorite = false;
    this.removeFromFavList(this.currentSchool);
  }
  get infoPanelData(): Map<string, string> | undefined {
    const sp = this.school && this.school.schoolPerformance;
    const op = this.school && this.school.overallPerformance;
    const about = this.schoolAbout;
    const leftPanel = this.school && this.school.leftPanel;

    if (!about) return undefined;
    
    const workSchedule = `${about.earlyEdMonday || ""},
    ${about.earlyEdTuesday || ""},
    ${about.earlyEdWednesday || ""},
    ${about.earlyEdThursday || ""},
    ${about.earlyEdFriday || ""},
    ${about.earlyEdSaturday || ""},
    ${about.earlyEdSunday || ""},
    `;

    let leftInfoPanelProps = new Map<string, string>();
    if (about) {
      const address = `${about.addressStreet} ${about.addressCity}, ${about.addressState} ${about.addressPostalCode}`;
      const starRating = leftPanel && leftPanel.starRating;
      const performanceRating = leftPanel && leftPanel.siteRating;

      leftInfoPanelProps = new Map([
        ["card-grade", leftPanel && leftPanel.letterGrade],
        ["assesment-rating", (sp && sp.studentAssessmentGrade) || "N/A"],
        ["progress-rating", (sp && sp.studentProgressGrade) || "N/A"],
        ["act-grade", (sp && sp.actGrade) || "N/A"], //actGrade
        ["graduation-grade", (sp && sp.graduationGrade) || "N/A"], //graduationGrade
        [
          "strength-of-diploma-grade",
          (sp && sp.strengthOfDiplomaGrade) || "N/A",
        ],
        ["school-address", address],
        [
          "school-phone",
          about.phoneNumber != ""
            ? about.phoneNumber
            : about.earlyEdPhoneNumber,
        ],
        ["school-email", about.earlyEdEmail],
        ["school-web", about.website],
        ["school-twitter", about.twitter],
        ["school-facebook", about.facebook],
        ["school-latitude", about.addressLatitude],
        ["school-longitude", about.addressLongitude],
        ["detail-type", this.showK12 ? "k-12" : "early-childhood"],
        ["performance-rating", performanceRating],
        ["star-rating", starRating],
        ["license-number", about.earlyEdLicenseNumber],
        ["site-code", this.$route.params.schoolId],
        ["work-schedule", workSchedule],
      ]);
    }
    return leftInfoPanelProps;
  }

  get showEE() {
    return (
      (!this.bothOrK12 &&
        !this.isEarlyChildhoodClicked &&
        !this.isK12Clicked) ||
      (this.bothOrK12 && (this.$route.path.includes("/ec-") ||(this.$route.path.includes("/Inspections"))) ) ||
      (this.isEarlyChildhoodClicked && !this.isK12Clicked)
    );
  }
  async  showInspection() {
   const result= await this.$store.dispatch("app/getIsCareFacilityApplicable", "IsCareFacility");
   this.IsCareFacility = result == "Y" ? true : false  ;
    return this.IsCareFacility;
  }

  get showK12() {
    return (
      (this.bothOrK12 &&
        !this.$route.path.includes("/ec-") && !this.$route.path.includes("/Inspections")&&
        !this.isEarlyChildhoodClicked &&
        !this.isK12Clicked) ||
      (!this.isEarlyChildhoodClicked && this.isK12Clicked)
    );
  }

  get showAcademicPerformanceTabSelected() {
    const k12 = this.$route.path.includes("/academic-performance");
    const ec = this.$route.path.includes("/ec-academic-performance");
    return k12 || ec;
  }

  get showDisciplineAndAttendanceTabSelected() {
    return this.$route.path.includes("/DisciplineandAttendance");
  }

  get showFinanceTabSelected() {
    return this.$route.path.includes("/finance");
  }

  get showCovidTabSelected() {
    return this.$route.path.includes("/COVID19Impact");
  }

  get showDistrictCovidTabSelected() {
    return this.$route.path.includes("/DistrictCOVID19Impact");
  }

  get showDistrictSpendingTabSelected() {
    return this.$route.path.includes("/DistrictSpending");
  }

    get showInspectionsTabSelected() {
    return this.$route.path.includes("/Inspections");
  }

  setWindowSize() {
    this.stickyHeight = parseInt(
      document.querySelector(".sticky-topbar")?.clientHeight + ""
    );
  }

}
